<template>
	<div class="tramer">
		<damage-wizard
			:is-listing="true"
			:md-size="6"
			:sm-size="6"
			:readonly="true"
			:preset="tramer"
			:has-bumper="true"
			:color="'grey lighten-4'"
		/>
	</div>
</template>

<script>
import DamageWizard from '@/components/enduser/wizard/Damage.vue';
import { issueType } from '@/common/enums';

export default {
	name: 'Tramer',
	components: {
		DamageWizard,
	},
	props: {
		data: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {};
	},
	computed: {
		tramer() {
			return this.data;
		},
	},
	methods: {
		setCarDamage(field, issue) {
			this.damage[field] = issue;
		},

		setCarDamageOnSvg(field) {
			if (this.readonly) return;
			let index = this.damage[field];
			if (index === this.issueType.REPLACED.id) index = this.issueType.NONE.id;
			else index += 1;
			this.setCarDamage(field, index);
		},
		getDamageIssueText(field) {
			return Object.values(issueType)
				.find((x) => x.id === this.damage[field])
				?.code.toUpperCase();
		},
		getFillColor(field) {
			let color = '';
			const issueId = this.damage[field];
			switch (issueId) {
				case issueType.NONE.id:
					color = issueType.NONE.color;
					break;
				case issueType.SCRATCHED.id:
					color = issueType.SCRATCHED.color;
					break;
				case issueType.PAINTED.id:
					color = issueType.PAINTED.color;
					break;
				case issueType.REPLACED.id:
					color = issueType.REPLACED.color;
					break;
				default:
					color = 'default';
					break;
			}
			return `${color}--fill`;
		},
		issueIconColor(issue) {
			let padding = '';
			if (this.darkTemplate) {
				padding = 'mx-3';
			} else padding = this.$vuetify.breakpoint.mdAndUp ? 'mx-5' : 'mx-2';

			return `${issue.color}--text ${padding}`;
		},
	},
};
</script>

<style></style>
