<template>
	<div class="tech-spec">
		<v-card-title class="font-weight-bold">Teknik Özellikler</v-card-title>
		<v-divider></v-divider>
		<div v-for="(techSpecCategory, techSpecCategoryIndex) in techSpecCategories" :key="`tech-${techSpecCategoryIndex}`">
			<v-card-subtitle>
				<div class="d-flex justify-start">
					<v-icon left small>fas fa-asterisk</v-icon>
					<div class="main-title">{{ techSpecCategory.name }}</div>
				</div>
			</v-card-subtitle>
			<v-card-text>
				<v-row dense>
					<v-col
						cols="12"
						md="5"
						:offset-md="specIndex % 2 !== 0 ? 2 : 0"
						class="d-flex justify-space-between"
						v-for="(spec, specIndex) in techSpecCategory.techSpecs"
						:key="`spec-${techSpecCategoryIndex}-${specIndex}`"
					>
						<div class="font-weight-bold">
							<v-icon left x-small class="template-color--text">far fa-circle-dot</v-icon>
							{{ spec.name }}
						</div>
						<div>
							{{ spec.value }}
						</div>
					</v-col>
				</v-row>
			</v-card-text>
		</div>

		<v-card-title class="font-weight-bold">Donanımlar</v-card-title>
		<v-divider></v-divider>
		<div
			v-for="(equipmentCategory, equipmentCategoryIndex) in equipmentCategories"
			:key="`equipment-${equipmentCategoryIndex}`"
		>
			<v-card-subtitle>
				<div class="d-flex justify-start">
					<v-icon left small>fas fa-asterisk</v-icon>
					<div class="main-title">{{ equipmentCategory.name }}</div>
				</div>
			</v-card-subtitle>
			<v-card-text>
				<v-row dense>
					<v-col
						cols="12"
						sm="6"
						md="3"
						v-for="(eq, eqIndex) in equipmentCategory.equipments"
						:key="`eq-${equipmentCategoryIndex}-${eqIndex}`"
					>
						<v-icon left x-small class="template-color--text">far fa-circle-dot</v-icon>
						{{ eq }}
					</v-col>
				</v-row>
			</v-card-text>
		</div>
	</div>
</template>

<script>
export default {
	name: 'technicalSpec',
	props: {
		data: {
			type: Object,
			default: null,
			required: true,
		},
	},
	computed: {
		techSpecCategories() {
			return this.data?.techSpecCategories;
		},
		equipmentCategories() {
			return this.data?.equipmentCategories;
		},
	},
};
</script>

<style lang="scss" scoped>
.tech-spec {
	.main-title {
		font-weight: 900;
		font-size: 14px;
	}
}
</style>
