/* eslint-disable no-unused-expressions */
import { required, email, helpers, sameAs } from 'vuelidate/lib/validators';

const phoneCheck = helpers.regex('phoneCheck', /^((0)(\d{3})(\d{3})(\d{2})(\d{2}))$/);

const contactMessageValidationMixins = {
	validations: {
		captchaValid: {
			required,
			sameAs: sameAs(() => true),
		},
		contactForm: {
			name: {
				required,
			},
			phone: {
				required,
				phoneCheck,
			},
			email: {
				email,
			},
			message: {
				required,
			},
			kvkkApproved: {
				required,
				sameAs: sameAs(() => true),
			},
		},
	},
	methods: {
		nameErrors() {
			const errors = [];
			const { $dirty, required } = this.$v.contactForm.name;

			if (!$dirty) return errors;
			!required && errors.push('Lütfen adınızı soyadınızı yazınız!');
			return errors;
		},
		phoneErrors() {
			const errors = [];
			const { $dirty, required, phoneCheck } = this.$v.contactForm.phone;

			if (!$dirty) return errors;
			!required && errors.push('Telefon numarası gereklidir!');
			!phoneCheck && errors.push('Telefon numaranız hatalı!');
			return errors;
		},
		emailErrors() {
			const errors = [];
			const { $dirty, email } = this.$v.contactForm.email;

			if (!$dirty) return errors;
			!email && errors.push('E-mail formatı hatalı!');
			return errors;
		},
		messageErrors() {
			const errors = [];
			const { $dirty, required } = this.$v.contactForm.message;

			if (!$dirty) return errors;
			!required && errors.push('Lütfen mesajınızı yazınız!');
			return errors;
		},
		kvkkApprovedErrors() {
			const errors = [];
			const { $dirty, required, sameAs } = this.$v.contactForm.kvkkApproved;
			if (!$dirty) return errors;
			!required && errors.push('Lütfen aydınlatma metnini onaylayın.');
			!sameAs && errors.push('aydınlatma metnini onaylamanız gerekiyor.');
			return errors;
		},
	},
};

export default contactMessageValidationMixins;
