<template>
	<v-card-text> <div v-html="desc"></div> </v-card-text>
</template>

<script>
export default {
	name: 'Description',
	props: {
		data: {
			type: String,
			default: null,
			required: true,
		},
	},
	computed: {
		desc() {
			return this.data;
		},
	},
};
</script>

<style></style>
