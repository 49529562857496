<template>
	<div>
		<vue-easy-lightbox
			ref="lightbox"
			:visible="gallery"
			:imgs="photos"
			:index="currentImage"
			:escDisabled="true"
			:scrollDisabled="true"
			:moveDisabled="true"
			@hide="handleHide"
		>
		</vue-easy-lightbox>

		<v-carousel
			hide-delimiters
			height="auto"
			@change="galleryChanged"
			:value="currentImage"
			:show-arrows="photos.length > 1"
		>
			<div class="favorite">
				<v-btn icon :width="favoriteIconSize" :height="favoriteIconSize" @click.prevent="addToFavorites(carId)">
					<v-icon small :color="isFavorite(carId) ? 'red' : 'grey'">fa-heart</v-icon>
				</v-btn>
			</div>

			<template v-slot:prev="{ on, attrs }">
				<div v-bind="attrs" v-on="on">
					back
					<v-btn
						class="direction-icon"
						:class="{ mobile: $vuetify.breakpoint.smAndDown }"
						depressed
						:width="directionIconSize"
						:height="directionIconSize"
						fab
						v-bind="attrs"
						v-on="on"
						@click.prevent="$refs.prev.click()"
						><v-icon centered small>fas fa-chevron-left</v-icon></v-btn
					>
				</div>
			</template>
			<template v-slot:next="{ on, attrs }">
				<div v-bind="attrs" v-on="on">
					next
					<v-btn
						class="direction-icon"
						:class="{ mobile: $vuetify.breakpoint.smAndDown }"
						depressed
						:width="directionIconSize"
						:height="directionIconSize"
						fab
						v-bind="attrs"
						v-on="on"
						@click.prevent="$refs.next.click()"
						><v-icon centered small>fas fa-chevron-right</v-icon></v-btn
					>
				</div>
			</template>
			<v-carousel-item v-for="(photo, pindex) in photos" :key="`car-photo-${pindex}`">
				<template v-slot:default>
					<v-img
						class="zoom-img"
						max-height="400"
						contain
						:aspect-ratio="4 / 3"
						:src="`${photo}`"
						:lazy-src="`${photo}`"
						@click="gallery = true"
					>
					</v-img>
				</template>
			</v-carousel-item>
		</v-carousel>

		<div class="full-show" @click="showImg">
			<v-icon small light>fas fa-magnifying-glass</v-icon> Büyük fotoğraf göster
		</div>
		<v-sheet light color="transparent" style="position: relative" v-if="photos.length > 1">
			<carousel
				ref="sliderCarousel"
				:responsive="responsive"
				:auto-height="true"
				:dots="false"
				:nav="false"
				:margin="5"
				:lazy-load="true"
				:video="true"
			>
				<v-img
					:key="`car-thumb-${thumbIndex}`"
					v-for="(photo, thumbIndex) in photos"
					contain
					:aspect-ratio="4 / 3"
					:src="`${photo}`"
					:lazy-src="`${photo}`"
					@click="thumbChange(thumbIndex)"
				>
					<div class="fill-height img-gradient" v-if="currentImage === thumbIndex"></div>
				</v-img>

				<template slot="prev">
					<span class="prev" ref="prev">
						<v-icon small class="template-color--text">fa-chevron-left</v-icon>
					</span>
				</template>
				<template slot="next" v-if="showNextButton()">
					<span class="next" ref="next">
						<v-icon small class="template-color--text">fa-chevron-right</v-icon>
					</span>
				</template>
			</carousel>
		</v-sheet>
	</div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox';
import carousel from 'vue-owl-carousel';
import carFavoriteMixin from '../../_mixins/carFavoriteMixin';

export default {
	name: 'DetailPhotos',
	mixins: [carFavoriteMixin],
	components: {
		carousel,
		VueEasyLightbox,
	},
	props: {
		carId: {
			type: String,
			required: true,
		},
		photos: {
			type: Array,
			default: () => [],
			required: true,
		},
	},
	data() {
		return {
			gallery: false,
			responsive: {
				0: {
					items: 3,
				},
				500: {
					items: 4,
				},
				700: {
					items: 4,
				},
				900: {
					items: 5,
				},
				1300: {
					items: 5,
				},
			},
			currentImage: 0,
		};
	},
	computed: {
		favoriteIconSize() {
			return this.$vuetify.breakpoint.mdAndUp ? 45 : 35;
		},
		directionIconSize() {
			return this.$vuetify.breakpoint.mdAndUp ? 45 : 35;
		},
	},
	methods: {
		showNextButton() {
			const closest = (orderedArray, value, valueGetter = (item) => item) =>
				orderedArray.find(
					(item, i) =>
						i === orderedArray.length - 1 ||
						Math.abs(value - valueGetter(item)) < Math.abs(value - valueGetter(orderedArray[i + 1])),
				);

			const fields = Object.keys(this.responsive).map((x) => parseInt(x, 10));
			const closestWidth = closest(fields, this.$vuetify.breakpoint.width);
			const length = Object.values(this.responsive)[fields.findIndex((x) => x === closestWidth)]?.items ?? 5;

			return this.photos.length > length;
		},
		galleryChanged(index) {
			this.currentImage = index;
		},
		thumbChange(index) {
			this.currentImage = index;
		},
		showImg() {
			this.gallery = true;
		},
		handleHide() {
			this.gallery = false;
		},
		preventMouseWhell(e) {
			if (e.deltaY > 0 || e.deltaY < 0) {
				e.preventDefault();
			} else if (e.wheelDeltaY < 0 || e.wheelDeltaY > 0) {
				e.preventDefault();
			}
		},
		mouseWhellPreventForLightbox(show) {
			setTimeout(() => {
				const { $el } = this.$refs.lightbox;

				if (show) {
					$el.addEventListener('wheel', (e) => {
						this.preventMouseWhell(e);
					});
				} else {
					$el.removeEventListener('wheel', this.preventMouseWhell);
				}
			}, 500);
		},
	},
	watch: {
		gallery: {
			handler(show) {
				this.mouseWhellPreventForLightbox(show);
			},
			deep: true,
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep {
	.direction-icon {
		background: rgba(0, 0, 0, 0.01) !important;
	}

	.img-gradient {
		background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 0%, transparent 100px);
	}
	.owl-carousel {
		.owl-stage-outer {
			padding: 5px 0px !important;

			.owl-item {
				cursor: pointer;
			}
		}
	}
	.prev {
		position: absolute;
		left: -20px;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
	}
	.next {
		position: absolute;
		right: -20px;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
	}

	.full-show {
		cursor: pointer;
		font-weight: bold;
		padding: 5px 0px;
	}

	.v-carousel {
		.v-window__next,
		.v-window__prev {
			background-color: transparent !important;
			border-radius: 0px;
			margin: 0px;
			top: 0px;
			bottom: 0px;
			width: 50%;
			text-indent: -999em;

			div {
				.direction-icon {
					background-color: rgba(255, 255, 255, 0.1) !important;
				}

				height: 100%;
			}
		}

		.v-window__prev {
			.direction-icon {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 15px;
			}
		}
		.v-window__next {
			.direction-icon {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 15px;
			}
		}
	}
}
</style>
