<template>
	<v-menu light offset-y left transition="scale-transition">
		<template v-slot:activator="{ on: menu, attrs }">
			<v-tooltip top>
				<template v-slot:activator="{ on: tooltip }">
					<v-btn
						:width="$vuetify.breakpoint.mobile ? 24 : 42"
						:height="$vuetify.breakpoint.mobile ? 24 : 42"
						color="transparent"
						v-bind="attrs"
						v-on="{ ...tooltip, ...menu }"
						depressed
						icon
					>
						<v-icon right color="grey lighten-1" :size="$vuetify.breakpoint.mobile ? 22 : 26"
							>fa-square-share-nodes</v-icon
						>
					</v-btn>
				</template>
				<span>Paylaş</span>
			</v-tooltip>
		</template>

		<v-list class="share-social">
			<v-list-item>
				<v-list-item-content>
					<ShareNetwork
						network="facebook"
						:url="url"
						:title="`${details.brand} ${details.model}`"
						:description="details.title"
						:hashtags="`${details.brand},${details.model}`"
					>
						<i class="fab fah fa-lg fa-facebook mr-2"></i>
						<span>Facebook'da paylaş</span>
					</ShareNetwork>
				</v-list-item-content>
			</v-list-item>
			<v-list-item>
				<v-list-item-content>
					<ShareNetwork
						network="twitter"
						:url="url"
						:title="`${details.brand} ${details.model}`"
						:description="details.title"
						:hashtags="`${details.brand},${details.model}`"
					>
						<i class="blue--text text--lighten-1 fab fah fa-lg fa-twitter mr-2"></i>
						<span>Twitter'da paylaş</span>
					</ShareNetwork>
				</v-list-item-content>
			</v-list-item>
			<v-list-item>
				<v-list-item-content>
					<ShareNetwork
						network="whatsapp"
						:url="url"
						:title="`${details.brand} ${details.model}`"
						:description="details.title"
						:hashtags="`${details.brand},${details.model}`"
					>
						<i class="green--text text--darken-1 fab fah fa-lg fa-whatsapp mr-2"></i>
						<span>Whatsapp ile paylaş</span>
					</ShareNetwork>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
export default {
	name: 'SocialShare',
	props: {
		details: {
			type: Object,
			required: true,
		},
	},
	computed: {
		url() {
			return decodeURI(`${window.location.origin}${this.$route.fullPath}`);
		},
	},
};
</script>

<style lang="scss" scoped>
.share-social {
	a {
		text-decoration: none !important;
		span {
			color: #333;
		}
	}
}
</style>
