<template>
	<div>
		<v-card-title class="px-0">Kredi Hesaplama</v-card-title>
		<v-row>
			<v-col cols="12" md="3">
				<v-card tile color="gradient-card" :class="{ 'blur-content': loading }">
					<v-card-text class="template-text--text">
						<div class="d-flex flex-column">
							<div class="text-subtitle-1 font-weight-bold">Aylık Ödeme</div>
							<div class="text-h4 font-weight-bold">
								{{ calculatedAmount.installmentPrice | tl({ fractionCount: 0, symbolPosition: 'front' }) }}
							</div>
							<div>Toplam Geri Ödenecek Tutar</div>
							<div class="d-flex justify-space-between">
								<div class="text-subtitle-1 font-weight-bold">
									{{ calculatedAmount.totalRefund | tl({ fractionCount: 0, symbolPosition: 'front' }) }}
								</div>
								<div class="text-subtitle-1 font-weight-bold">{{ installmentValue }} AY VADE</div>
								<div class="text-subtitle-1 font-weight-bold">%{{ interestRate }} FAİZ</div>
							</div>
						</div>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col>
				<v-card tile color="transparent" flat light height="100%">
					<v-card-text class="fill-height">
						<v-row align="center" class="fill-height ml-md-15">
							<v-col cols="12" md="4">
								<div class="text-subtitle-1 mb-1">Maksimum Kredi (TL)</div>
								<v-text-field-money
									v-model="creditData.maxCreditPrice"
									v-bind:properties="{
										color: 'template-color',
										inputmode: 'numeric',
										outlined: true,
										dense: true,
										placeholder: 'Maksimum kredi tutarı',
										hideDetails: 'auto',
									}"
									v-bind:options="{
										locale: 'tr-TR',
										precision: 0,
										length: 7,
									}"
									@keyup.native="maxCreditDebounce"
									@input="input"
								/>
							</v-col>
							<v-col cols="12" md="4">
								<div class="text-subtitle-1 mb-1">Peşinat (TL)</div>
								<v-text-field-money
									v-model="creditData.advancePayment"
									v-bind:properties="{
										color: 'template-color',
										inputmode: 'numeric',
										outlined: true,
										dense: true,
										placeholder: 'Peşinat tutarı',
										hideDetails: 'auto',
									}"
									v-bind:options="{
										locale: 'tr-TR',
										precision: 0,
									}"
									@keyup.native="advancePaymentDebounce"
									@input="input"
								/>
							</v-col>
							<v-col cols="12" md="4">
								<div class="text-subtitle-1 mb-1">Vade (Ay)</div>
								<v-select
									color="template-color"
									hide-details="auto"
									placeholder="Vade seçiniz"
									outlined
									dense
									:items="installments"
									v-model="creditData.selectedInstallment"
									@change="installmentChange"
									@input="input"
								></v-select>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
/* eslint-disable no-restricted-properties */
export default {
	name: 'CreditCalculator',
	props: {
		dataValue: {
			type: Object,
			default: null,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			constMaxCreditPrice: null,
			constAdvancePayment: null,
			creditData: {
				maxCreditPrice: null,
				advancePayment: null,
				selectedInstallment: 24,
			},
		};
	},
	computed: {
		isLoading() {
			return this.loading;
		},
		installments() {
			return this.dataValue.installments;
		},
		installmentValue() {
			return this.creditData.selectedInstallment;
		},
		interestRate() {
			return parseFloat(this.dataValue.interestRate.toFixed(2));
		},
		calculated() {
			if (this.isLoading) return { installmentPrice: 0, totalRefund: 0 };
			const rate = this.dataValue.interestRate / 100;
			const dTemp3 = rate + 1.0;
			const dTemp2 = Math.pow(dTemp3, this.installmentValue);
			const price = ((this.creditData.maxCreditPrice * dTemp2) / (1.0 * (dTemp2 - 1.0))) * rate;
			return { installmentPrice: price, totalRefund: price * this.installmentValue };
		},
		calculatedWithKkdfBsmv() {
			const monthlyCost =
				this.dataValue.interestRate * (1 + (this.dataValue.creditKkdf + this.dataValue.creditBsmv) / 100);
			const rate = monthlyCost / 100;
			const installmentAmount =
				(this.creditData.maxCreditPrice * (rate * Math.pow(1 + rate, this.installmentValue))) /
				(Math.pow(1 + rate, this.installmentValue) - 1);
			const price = Math.floor(installmentAmount * 100) / 100;
			return { installmentPrice: price, totalRefund: price * this.installmentValue };
		},
		calculatedAmount() {
			if (this.dataValue.creditKkdf !== null && this.dataValue.creditBsmv !== null) {
				return this.calculatedWithKkdfBsmv;
			}
			return this.calculated;
		},
		maxCreditDebounce() {
			return this.$debounce(this.checkMaxCredit, 1000, { trailing: true });
		},
		advancePaymentDebounce() {
			return this.$debounce(this.checkAdvancePayment, 1000, { trailing: true });
		},
		insurancePriceDiff() {
			return this.dataValue.mainSalesPrice - this.dataValue.price;
		},
	},
	mounted() {
		this.creditData.maxCreditPrice = this.dataValue.price * this.dataValue.maxCreditRate;
		this.creditData.advancePayment = this.dataValue.price + this.insurancePriceDiff - this.creditData.maxCreditPrice;

		this.constMaxCreditPrice = this.creditData.maxCreditPrice;
		this.constAdvancePayment = this.creditData.advancePayment;

		const [max] = this.installments.sort((a, b) => b - a);
		this.creditData.selectedInstallment = max;
	},
	methods: {
		input() {
			this.loading = true;
		},
		checkMaxCredit(event) {
			const targetValue = event.target.value.replace(/\./g, '');
			if (targetValue > this.constMaxCreditPrice) this.creditData.maxCreditPrice = this.constMaxCreditPrice;

			const advancePayment = this.dataValue.price + this.insurancePriceDiff - this.creditData.maxCreditPrice;
			this.creditData.advancePayment = advancePayment;

			this.loading = false;
		},
		checkAdvancePayment(event) {
			const targetValue = event.target.value.replace(/\./g, '');
			if (targetValue < this.constAdvancePayment) this.creditData.advancePayment = this.constAdvancePayment;
			else if (targetValue > this.dataValue.price) this.creditData.advancePayment = this.dataValue.price;

			const maxCreditPrice = this.dataValue.price + this.insurancePriceDiff - this.creditData.advancePayment;
			this.creditData.maxCreditPrice = maxCreditPrice > 0 ? maxCreditPrice : 0;

			this.loading = false;
		},
		installmentChange() {
			setTimeout(() => {
				this.loading = false;
			}, 1000);
		},
	},
};
</script>

<style lang="scss" scoped>
.gradient-card {
	background: var(--v-template-color-base);
	background: -moz-radial-gradient(circle, var(--v-template-color-base) 0%, var(--v-template-color-darken1) 100%);
	background: -webkit-radial-gradient(circle, var(--v-template-color-base) 0%, var(--v-template-color-darken1) 100%);
	background: radial-gradient(circle, var(--v-template-color-base) 0%, var(--v-template-color-darken1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=var(--v-template-color-base),endColorstr=var(--v-template-color-darken1),GradientType=1);
	transition: 0.7s -webkit-filter linear;
}

.blur-content {
	filter: blur(4px);
	backdrop-filter: blur(4px);
}
</style>
