<template>
	<div>
		<v-breadcrumbs class="links-bread-crumb px-0 py-0" light :items="breadCrumbs" divider=".">
			<template v-slot:item="{ item }">
				<v-breadcrumbs-item
					:to="item.to"
					@click="go(item.go)"
					:disabled="item.disabled"
					:class="{ 'd-inline-block text-truncate': $vuetify.breakpoint.mobile }"
					:style="$vuetify.breakpoint.mobile ? 'max-width:50px' : 'auto'"
				>
					{{ item.text }}
				</v-breadcrumbs-item>
			</template>
			<template v-slot:divider>
				<v-icon color="grey lighten-2">fas fa-chevron-right</v-icon>
			</template>
		</v-breadcrumbs>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'FilterBreadCrumb',
	props: {
		carDetails: {
			type: Object,
			required: true,
		},
	},
	computed: {
		...mapGetters(['filterPayload']),
		filterOptions() {
			return this.filterPayload;
		},
		detail() {
			return this.carDetails;
		},
		breadCrumbs() {
			return [
				{
					text: 'Araçlar',
					disabled: false,
					to: { name: 'cars' },
				},
				{
					text: this.detail.brand,
					disabled: false,
					go: () => this.goBrand(this.detail.brandId),
				},
				{
					text: this.detail.model,
					disabled: false,
					go: () => this.goModel(this.detail.brandId, this.detail.modelId),
				},
				{
					text: this.detail.title,
					disabled: true,
				},
			];
		},
	},
	methods: {
		go(fn) {
			if (fn) {
				this.filterOptions.brands = [];
				this.filterOptions.models = [];
				fn();
			}
		},
		goBrand(id) {
			this.filterOptions.pageNumber = 1;
			this.filterOptions.brands = [id];
			this.$router.push({ name: 'cars' });
		},
		goModel(brand, model) {
			this.filterOptions.pageNumber = 1;
			this.filterOptions.brands = [brand];
			this.filterOptions.models = [model];
			this.$router.push({ name: 'cars' });
		},
	},
};
</script>

<style lang="scss">
.links-bread-crumb {
	.v-breadcrumbs {
		&__item {
			cursor: pointer !important;
			color: var(--v-secondary-lighten2);
			&--disabled {
				color: var(--v-secondary-lighten4);
			}
		}
	}
}
.sticky-desc {
	&.active {
		.v-breadcrumbs__divider {
			.v-icon {
				color: var(--v-secondary-lighten5) !important;
			}
		}
	}
}
.details-tab {
	.v-tabs-bar {
		background: var(--v-template-color-base);
		background: linear-gradient(
			90deg,
			var(--v-template-color-darken1) 0%,
			var(--v-template-color-base) 50%,
			var(--v-template-color-lighten1) 100%
		);

		.v-tab {
			color: var(--v-template-color-darken3);
			text-shadow: 0px 0px 1px var(--v-template-color-lighten1);
			font-weight: 800;

			&--active {
				font-weight: bold;
				color: var(--v-template-text-base);
				background: var(--v-template-color-darken1);
				text-shadow: none;
			}
		}
	}
}
</style>
